// Gfield Validation on the fly.
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_container>input[type="text"]').forEach(el => {
    el.addEventListener('keyup',()=>{validTextFields(el)});
    el.addEventListener('blur',()=>{validTextFields(el)});
    validTextFields(el,true);
})
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_container>textarea').forEach(el => {
    el.addEventListener('keyup',()=>{validTextFields(el)});
    el.addEventListener('blur',()=>{validTextFields(el)});
    validTextFields(el,true);
})
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_container>input[type="email"]').forEach(el => {
    el.addEventListener('keyup',()=>{validEmailFields(el)});
    el.addEventListener('blur',()=>{validEmailFields(el)});
    validEmailFields(el,true);
})
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_container>input[type="tel"]').forEach(el => {
    el.addEventListener('keyup',()=>{validTelFields(el)});
    el.addEventListener('blur',()=>{validTelFields(el)});
    validTelFields(el,true);
})
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_container>input[type="number"]').forEach(el => {
    el.addEventListener('keyup',()=>{validNbFields(el)});
    el.addEventListener('blur',()=>{validNbFields(el)});
    validNbFields(el,true);
})

//complex
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_complex input[type="text"]').forEach(el => {
    el.addEventListener('keyup',()=>{validComplexTextFields(el)});
    el.addEventListener('blur',()=>{validComplexTextFields(el)});
    validComplexTextFields(el,true);
})
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_complex textarea').forEach(el => {
    el.addEventListener('keyup',()=>{validComplexTextFields(el)});
    el.addEventListener('blur',()=>{validComplexTextFields(el)});
    validComplexTextFields(el,true);
})
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_complex input[type="email"]').forEach(el => {
    el.addEventListener('keyup',()=>{validComplexEmailFields(el)});
    el.addEventListener('blur',()=>{validComplexEmailFields(el)});
    validComplexEmailFields(el,true);
})
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_complex input[type="tel"]').forEach(el => {
    el.addEventListener('keyup',()=>{validComplexTelFields(el)});
    el.addEventListener('blur',()=>{validComplexTelFields(el)});
    validComplexTelFields(el,true);
})
document.querySelectorAll('.gfield_contains_required.gfield>.ginput_complex input[type="number"]').forEach(el => {
    el.addEventListener('keyup',()=>{validComplexNbFields(el)});
    el.addEventListener('blur',()=>{validComplexNbFields(el)});
    validComplexNbFields(el,true);
})

function validTextFields(el=false,onLoad=false){
    if(el && el.value){
        console.log('Other - '+el.value);
        if(el.value != ''){
            el.parentElement.parentElement.classList.remove('gfield_error');
            el.parentElement.parentElement.classList.add('gfield_valid');
        }else{
            if(!onLoad){
                el.parentElement.parentElement.classList.add('gfield_error');
                el.parentElement.parentElement.classList.remove('gfield_valid');
            }
        }
    }else if(el){
        el.parentElement.parentElement.classList.remove('gfield_valid');
    }
}
function validComplexTextFields(el=false,onLoad=false){
    if(el && el.value){
        console.log('Other Complex - '+el.value);
        if(el.value != ''){
            el.parentElement.classList.remove('gfield_error');
            el.parentElement.classList.add('gfield_valid');
        }else{
            if(!onLoad){
                el.parentElement.classList.add('gfield_error');
                el.parentElement.classList.remove('gfield_valid');
            }
        }
    }else if(el){
        el.parentElement.parentElement.classList.remove('gfield_valid');
    }
}
function validEmailFields(el=false,onLoad=false){
    if(el && el.value){
        console.log('Email - '+el.value);
        if(el.value != '' && validateEmail(el.value)){
            el.parentElement.parentElement.classList.remove('gfield_error');
            el.parentElement.parentElement.classList.add('gfield_valid');
        }else{
            if(!onLoad){
                el.parentElement.parentElement.classList.add('gfield_error');
                el.parentElement.parentElement.classList.remove('gfield_valid');
            }
        }
    }else if(el){
        el.parentElement.parentElement.classList.remove('gfield_valid');
    }
}
function validComplexEmailFields(el=false,onLoad=false){
    if(el && el.value){
        console.log('Email Complex - '+el.value);
        if(el.value != '' && validateEmail(el.value)){
            el.parentElement.classList.remove('gfield_error');
            el.parentElement.classList.add('gfield_valid');
        }else{
            if(!onLoad){
                el.parentElement.classList.add('gfield_error');
                el.parentElement.classList.remove('gfield_valid');
            }
        }
    }else if(el){
        el.parentElement.parentElement.classList.remove('gfield_valid');
    }
}
function validTelFields(el=false,onLoad=false){
    if(el && el.value){
        console.log('Tel - '+el.value);
        if(el.value != '' && validateTel(el.value)){
            el.parentElement.parentElement.classList.remove('gfield_error');
            el.parentElement.parentElement.classList.add('gfield_valid');
        }else{
            if(!onLoad){
                el.parentElement.parentElement.classList.add('gfield_error');
                el.parentElement.parentElement.classList.remove('gfield_valid');
            }
        }
    }else if(el){
        el.parentElement.parentElement.classList.remove('gfield_valid');
    }
}
function validComplexTelFields(el=false,onLoad=false){
    if(el && el.value){
        console.log('Tel Complex - '+el.value);
        if(el.value != '' && validateTel(el.value)){
            el.parentElement.classList.remove('gfield_error');
            el.parentElement.classList.add('gfield_valid');
        }else{
            if(!onLoad){
                el.parentElement.classList.add('gfield_error');
                el.parentElement.classList.remove('gfield_valid');
            }
        }
    }else if(el){
        el.parentElement.parentElement.classList.remove('gfield_valid');
    }
}
function validNbFields(el=false,onLoad=false){
    if(el && el.value){
        console.log('Number - '+el.value);
        if(el.value != '' && !isNaN(el.value)){
            el.parentElement.parentElement.classList.remove('gfield_error');
            el.parentElement.parentElement.classList.add('gfield_valid');
        }else{
            if(!onLoad){
                el.parentElement.parentElement.classList.add('gfield_error');
                el.parentElement.parentElement.classList.remove('gfield_valid');
            }
        }
    }else if(el){
        el.parentElement.parentElement.classList.remove('gfield_valid');
    }
}
function validComplexNbFields(el=false,onLoad=false){
    if(el && el.value){
        console.log('Number Complex - '+el.value);
        if(el.value != '' && !isNaN(el.value)){
            el.parentElement.classList.remove('gfield_error');
            el.parentElement.classList.add('gfield_valid');
        }else{
            if(!onLoad){
                el.parentElement.classList.add('gfield_error');
                el.parentElement.classList.remove('gfield_valid');
            }
        }
    }else if(el){
        el.parentElement.parentElement.classList.remove('gfield_valid');
    }
}
function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}
function validateTel(tel) {
    var re = "^\\s*(?:\\+?(\\d{1,3}))?[-. (]*(\\d{3})[-. )]*(\\d{3})[-. ]*(\\d{4})(?: *x(\\d+))?\\s*$";
    re = new RegExp(re);
    return re.test(tel);
}

